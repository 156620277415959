import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { SectionContainer } from "../components/micros"

const PrivacyPolicy = () => (
  <Layout>
    <Seo title="Privacy Policy" />
    <SectionContainer style={{ paddingTop: "100px" }}>

      <h1>
        Privacy Policy
      </h1>
      <p>

        Your privacy and the information you share with us is important to SUMMON JOB,
        a brand owned by FRONT STAR SEBASTIAN SZCZEPAŃSKI  VAT:  PL5213889460, hereinafter referred to as “SUMMON JOB”.
        We want to ensure you understand how we use and protect information collected about or provided by visitors to our website.
        We are mindful of data privacy and have adopted best practices in compliance with applicable privacy law and regulations,
        including the European Union General Data Protection Regulation (“GDPR”).
      </p>

      <h2>
        Use of this website
      </h2>
      <p>
        This privacy policy outlines the information collected from visitors to our site
        and how it may be stored and processed within SUMMON JOB globally as described below.
      </p>
      <p>
        This website provides content and services for a professional and consumer audience
        and is not intended for individuals under the age of 18 years old.
        We do not knowingly collect or store personal data provided by anyone under 18 years of age.
      </p>
      <p>
        If you are a European Union (EU) resident, please note that there are a number
        of provisions in this privacy policy that apply uniquely to you.
      </p>

      <h2>
        What information is collected
      </h2>

      <p>
        We collect data about you that is limited to the kind of information that can be found on a typical business card:
        first name, last name, job title, employer/company name, work address, work email, and work phone number.
        In some cases, we may ask you to provide additional professional information such as age, gender, personal photo, marital status,
        education, employment history, reference contact details, employment history, immigration status, nationality, citizenship,
        place of birth, information regarding current income, interests and goals, the size of the company you work for, and/ or
        industry type. Please note that the above list of personal data we may collect is not exhaustive.
      </p>

      <p>
        To enhance and/or update any information provided, we may combine
        it with professional information or personal data collected from third party sources.
        Personal data referred to in this document is the personal and professional information you provide,
        as well as information that we obtain from third party sources,
        both of which is typically combined into one record.

      </p>

      <p>
        This site also collects and stores certain information automatically
        using cookies and similar technologies, including IP addresses,
        the region or general location of a computer or device accessing the internet,
        browser type, operating system, page view history, and other usage information.
        This information assists us in improving our website, and we only use this information for statistical analysis purposes.
      </p>
      {/* <p>
        However, if any cookie can specifically identify a computer, mobile device,
        or tablet (“Device”), or the person using that Device, and you are an EU resident,
        under the GDPR, this is personal data. Therefore, this priva
      </p> */}

      <h2>
        How we collect it
      </h2>
      <p>
        Data may be collected when you register to receive any of the content or services offered by SUMMON JOB
        or other occurrences such as publications, subscriptions, contests, newsletters, memberships,
        premium content, webcasts, video, white papers, online seminars, conferences and events.
        SUMMON JOB, will collect and use information acquired from professional recruiting sites,
        resumes and/or business- oriented social networking sites about you in a way that is consistent
        with our responsibilities and your rights under The General Data Protection Regulation (GDPR) (EU) 2016/679.
        Primarily, SUMMON JOB offered services are executive search and services for executive-level positions in our clients’ organisations.
      </p>
      <p>
        What happens if you don’t provide personal data or choose not to consent to sharing personal data?
        For us to provide requested content or services, your personal data is required.
        You are under no statutory or contractual obligation to provide data to SUMMON JOB
        however if you do not consent to SUMMON JOB storing your personal data or setting up a personal
        registration for you, we are unable to process your information without receipt of consent to process and store your personal information.
      </p>


      <h2>
        How do we safeguard your personal data? <br />
        Data Security
      </h2>
      <p>
        SUMMON JOB is committed to GDPR and data security.
        We recognise that information is a vital asset to any organisation
        and take our responsibilities under the GDPR seriously.
        We will comply with The General Data Protection Regulation (GDPR) (EU) 2016/679
        in the way we use and share your personal data.
        Among other things, this means that we will only use your personal data:
      </p>
      <ul>
        <li>
          Fairly and lawfully
        </li>
        <li>
          As set out in the legislation and this policy
        </li>
        <li>
          To the extent necessary for these purposes
        </li>
      </ul>

      <p>
        Virtually all of our activities create information assets in one form or another
        and we have ensured that safeguards are in place to protect your personal data from loss,
        misuse, unauthorised use, access, inadvertent disclosure, alteration, and destruction.
        We furthermore require the third parties we contract with to support SUMMON JOB’s business
        operations to employ reasonable security measures as well (see “Sharing your information with others” below).
        In relation to the information that you provide to us, we are legally responsible for how that information is handled.
      </p>

      <h2>
        Data Storage
      </h2>
      <p>

        Your data may be stored using SUMMON JOB’s own secure on-site servers or other internally hosted technology.
        Your data may also be stored by third parties, via cloud services or other technology, with whom SUMMON JOB has contracted with,
        to support our business operations (see “Sharing your information with others” below).
      </p>

      <p>

        These third parties do not have access to or permission to use your personal data other than for cloud storage and retrieval,
        and SUMMON JOB requires such parties to employ at least the same level of security that we use
        to protect your personal data (see “Data Security” above).
      </p>

      <h2>
        Notification of Breaches
      </h2>
      <p>
        We are aware of our responsibility as a data controller to protect your information
        and to only share details with third parties that SUMMON JOB has contracted with.
      </p>
      <p>
        In the unlikely event that our computer systems are compromised and
        there is a potential loss of confidentiality we will report this breach to you.
      </p>
      <p>
        In the unlikely event that personal data is taken or exposed from SUMMON JOB records without our consent,
        we will provide reports surrounding that exposure within 72 hours of being notified of such an event and
        take any action necessary to restore security of your personal data.
      </p>

      <h2>
        What we do with the personal data we collect
      </h2>
      <p>
        We use the personal data that we collect to correspond with you,
        consider you for professional opportunities, action your requests,
        improve the services we provide, and present relevant information and services.
      </p>

      <p>
        Your data may be used to send important announcements, such as
        communications about your registration, transactions, and
        changes to SUMMON JOB’s terms and conditions, policies, and/or other purposes.
        We may use your data for the performance of our retained executive search or other services.
        Your data may be used to provide content or services you request.
        Your data may be used for data analysis, and research to improve our content and services.
        The legal basis we have for processing your personal data GDPR requires data collectors,
        such as SUMMON JOB, to have a legal basis to use the personal data of EU residents.
        This section applies to any such data collected by SUMMON JOB, its subsidiaries and/or associates.
      </p>

      <p>
        We use the personal data that we collect to correspond with you,
        consider you for professional opportunities, action your requests,
        improve the services we provide, and present relevant information and services.
      </p>

      <p>
        Per GDPR regulations, after May 25, 2018, in certain circumstances we may otherwise possess your personal data
        if we have a legitimate interest in doing so and your rights and freedoms are not being infringed upon.
      </p>
      <p>
        If we process your personal data for legitimate interests, we will do so in a considerate and balanced way
        taking your rights under data protection and any other relevant law into consideration.
      </p>

      <h2>
        Sharing your information with others
      </h2>
      <p>
        We may share your personal data to deliver content and services for business operations with clients, or to comply with valid legal processes.
      </p>
      {/* If you are an EU resident, we will only share your personal data with third parties outside of SUMMON JOB, with your consent or based on entering contract or contracts. */}

      <h2>International Data Transfers</h2>
      <p>
        This section shall apply to any personal data collected by SUMMON JOB from EU residents.
      </p>

      <p>
        If SUMMON JOB shares your personal data between SUMMON JOB locations or with any other third party
        as described in this privacy policy and your personal data will be transferred to a State which is not
        a Member State of either the European Union or the European Economic Area,
        or deemed adequate by the European Commission, SUMMON JOB (as a data controller/data exporter)
        will only conduct such transfer (to a data processor/data importer) if there are suitable safeguards in place,
        such as binding corporate rules, standard contractual clauses, approved Codes of Conduct,
        or approved certification mechanism. For more information, please contact our GDPR Compliance Officer at GDPR@SUMMON JOB.pl
      </p>

      <h2>
        How long we keep your personal data
      </h2>
      <p>
        We retain your personal data for the duration of your business relationship with us,
        and we hold your information only as long as necessary for each purpose we use it.
      </p>

      <h2>
        Your rights and preferences
      </h2>
      <p>
        As a data subject, you have a number of rights. You can:
        <ul>
          <li>
            require the organisation to change incorrect or incomplete data;
          </li>
          <li>
            access and obtain a copy of your data on request;
          </li>
          <li>
            require the organisation to delete or stop processing your data, for example where the data is no longer necessary for the purposes of processing; and
          </li>
          <li>
            object to the processing of your data where SUMMON JOB is relying on its legitimate interests as the legal ground for processing.
          </li>
        </ul>
      </p>

      <p>
        If you would like to exercise any of these rights, please contact our GDPR Compliance Officer at gdor@summonjob.com
      </p>

      <p>
        If SUMMON JOB receive a request for access to personal information we will verify your identify before releasing your personal data to you.
      </p>

      <p>
        If you would like to make a request for access to your personal information,
        please contact our GDPR Compliance Officer at gdor@summonjob.com
      </p>


      <h2>
        Contacting us, exercising your information rights and Complaints
      </h2>

      <p>
        If you have any questions or comments about this Privacy Policy, wish to exercise your information rights in connection with the personal data you have shared with us or wish to complain, please contact: The GDPR Compliance Officer at SUMMON JOB at gdor@summonjob.com We will process SARs within 20 days, SAR responses are usually free, but we reserve the right to charge for excessive or unfounded requests. We fully comply with Data Protection legislation and will assist in any investigation or request made by the appropriate authorities.
      </p>
      {/* <h1>We are happy to help!</h1> */}
      {/* <span> Send us an email to </span><br /> */}
      {/* <a href="mailto:contact@summonjob.com">contact@summonjob.com </a> */}
    </SectionContainer>
  </Layout>
)

export default PrivacyPolicy
